<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div v-if="isItem" class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Product</label>
                        <vField
                            v-model="data.product_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                            disabled="disabled"
                        />
                        <v-select
                            placeholder="Select Product"
                            v-model="data.product_id"
                            :options="products"
                            label="text"
                            :reduce="name => name.id"
                            disabled="disabled"
                        />
                    </div>
                </div>
                <div v-else class="col-md-4 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="product_id">Account Heads</label>
                        <vField
                            v-model="data.account_head_id"
                            name="product_id"
                            type="text"
                            class="form-control d-none"
                            disabled="disabled"
                        />
                        <v-select
                            placeholder="Select Head"
                            v-model="data.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <template v-if="isItem">
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="rate">Rate</label>
                            <vField
                                v-model="data.rate"
                                name="rate"
                                type="number"
                                class="form-control text-right"
                            />
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label class="form-label" for="quantity">
                                    Quantity <span v-if="productStock !== null">({{productStock}})</span>
                            </label>
                            <vField
                                v-model="data.quantity"
                                name="quantity"
                                type="number"
                                class="form-control text-right"
                                @blur="validateQuantity()"
                                disabled="disabled"
                            />
                        </div>
                    </div>
                </template>

                <div v-if="isItem" class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            readonly
                            v-model="amount"
                            name="amount text-right"
                            type="number"
                            class="form-control text-right"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div v-else class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount</label>
                        <vField
                            v-model="data.amount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-percent">Discount Percent</label>
                        <vField
                            v-model="data.discount_percent"
                            name="discount-percent"
                            type="number"
                            class="form-control text-right"
                            max="100"
                            min="0"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="discount-amount">Discount Amount</label>
                        <vField
                            v-model="data.discount_amount"
                            name="discount-amount"
                            type="number"
                            class="form-control text-right"
                            min="0"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="quantity">Amount after discount</label>
                        <vField
                            v-model="amountAfterDiscount"
                            name="amount"
                            type="number"
                            class="form-control text-right"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat">VAT</label>
                        <vField
                            v-model="data.vat"
                            id="vat"
                            as="select"
                            name="vat"
                            class="form-select"
                            disabled="disabled"
                        >
                            <option
                                v-for="(vat, i) in vatRate"
                                :value="vat.value"
                                :key="i"
                            >
                                {{vat.label}}
                            </option>
                        </vField>
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="vat_amount">VAT amount</label>
                        <vField
                            v-model="data.vat_amount"
                            name="vat_amount"
                            type="number"
                            class="form-control text-right"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Total Amount</label>
                        <vField
                            v-model="totalAmount"
                            readonly
                            name="total_amount"
                            type="number"
                            class="form-control text-right"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Business Unit</label>
                        <v-select
                            placeholder="Select Business"
                            v-model="data.business_id"
                            :options="business"
                            label="name"
                            :reduce="name => name.id"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Project</label>
                        <v-select
                            placeholder="Select Project"
                            v-model="data.project_id"
                            :options="projects"
                            label="name"
                            :reduce="name => name.id"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-2 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="total_amount">Select Cost Centre</label>
                        <v-select
                            placeholder="Select Cost Centre"
                            v-model="data.cost_centre_id"
                            :options="costCentres"
                            label="name"
                            :reduce="name => name.id"
                            disabled="disabled"
                        />
                    </div>
                </div>

                <div class="col-md-8 col-12">
                    <div class="mb-1">
                        <label class="form-label" for="description">Description</label>
                        <vField
                          style="height: 38px"
                          as="textarea"
                          name="description"
                          v-model="data.description"
                          class="form-control"
                          disabled="disabled"
                        />
                    </div>
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref,computed } from "@vue/runtime-core"
import figureFormatter from '@/services/utils/figureFormatter'
import {inject, onMounted, watch} from "vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";

const {commaFormat} = figureFormatter()
const showError = inject('showError');

let accountEditable = ref(0)
const {fetchProductStock} = handlePurchaseAndSales();
const route = useRoute();
const productStock = ref(null);
const selectedProduct = ref({});

const pr = defineProps({
  title: String,
  data: Object,
  isItem: Boolean,
  index: Number,
  vatRate: Array,
  products: Array,
  accountHeads: Array,
  business: Array,
  projects: Array,
  costCentres: Array,
  locationId : {
      type: Number,
      default: null
  }
})

const amount = computed(() => {
    if(pr.isItem) {
        return pr.data.rate * pr.data.quantity
    }
     if(!pr.isItem) {
         return pr.data.amount
     }
})

const amountAfterDiscount = computed(() => {
  const discountAmount = pr.data.discount_amount === '' ? 0 :parseFloat(pr.data.discount_amount)
  if(discountAmount === 0) {
    return amount.value;
  }
  return amount.value - discountAmount
})

const vatAmount = computed(() => {
    if(pr.data.vat === 0) {
        return 0
    }

    if(pr.data.vat && pr.isItem) {
        return (pr.data.vat/100 * amountAfterDiscount.value).toFixed(2)
    }
    return (pr.data.vat/100 * amountAfterDiscount.value).toFixed(2);
})

watch(vatAmount, (newValue, oldValue) => {
  if(isNaN(oldValue)){
    pr.data.vat_amount  = pr.data.vat_amount;
  }else {
    pr.data.vat_amount  = newValue;
  }
})

const totalAmount = computed(() => {
    if(pr.data.vat == 0) {
        return amountAfterDiscount.value
    }
    return parseInt(amountAfterDiscount.value) + parseInt(vatAmount.value)
})

const productId = computed(() => pr.data.product_id)
const locationId = computed(() => pr.locationId)
const discountPercent = computed(() => {
  return pr.data.discount_percent
})

watch(locationId, (newValue, oldValue) => {
    clearFields();
})

watch(productId, (newValue, oldValue) => {
    if(newValue === null) {
        clearFields();
        return;
    }

    //check if the product is a service
    selectedProduct.value = pr.products.find(prod => prod.id === newValue)
    if(selectedProduct.value.product_type === 'services') {
        productStock.value = null;
        return;
    }

    fetchStock(newValue);
})

watch(discountPercent, (value) => {
  let discount = 0;
  value = parseFloat(value);
  if(value && value > 0 && amount.value > 0) {
    discount = amount.value * (value/100);
  }
  pr.data.discount_amount = discount.toFixed(2);
})

watch(amount, (value) => {
  if (value > 0 && discountPercent && discountPercent.value > 0) {
    pr.data.discount_amount = (value * (discountPercent.value/100)).toFixed(2)
    return ;
  }
  pr.data.discount_amount = 0;
})

//method
const fetchStock = (id) => {
  if (productId.value !== null && productId.value != undefined) {
    let query = `?company_id=${route.params.companyId}`;
    query += ((locationId.value !== null) ? `&location_id=${locationId.value}` : '');
    fetchProductStock(id, query).then(res => {
      productStock.value = null;
      if (res.status === false) {
        showError(res.message);
        return;
      }
      productStock.value = res.data.stock;
    })
  }
}

onMounted(() => {
    //check if the product is a service
    if (pr.products.length > 0 && productId.value) {
        selectedProduct.value = pr.products.find(prod => prod.id === productId.value)
        if(selectedProduct.value.product_type === 'services') {
            productStock.value = null;
            return;
        }
        fetchStock(productId.value);
    }
})

//methods
const validateQuantity = () => {
    if(pr.data.quantity !== null && pr.data.quantity < 1){
        showError('Product Quantity must be greater than 0!')
        return;
    }

    if(selectedProduct.value.product_type === 'services' ) {
        return;
    }

    if(productStock.value === null || pr.data.quantity > productStock.value) {
        showError('Product quantity should be equal or smaller than product stock!')
        pr.data.quantity = productStock.value ?? 0;
    }
}

const clearFields = () => {
    productStock.value = null;
    pr.data.product_id = null;
    pr.data.quantity = 0;
    pr.data.rate = 0;
}

</script>


<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>

